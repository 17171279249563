export const hourMinuteRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
export const hourMinuteRegexMessage = 'Duration format should be hh:mm';

/**
 * Calculate shift length between start date and end time.
 * Return as string in format hh:mm
 *
 * @param {object} params
 * @returns {string}
 */
export function shiftLengthCalc(params) {
  const { date, startTime, endTime } = params.row;
  if (!date || !startTime || !endTime) {
    return '';
  }

  const sd = startTime instanceof Date ? startTime : dateFromHourMinutes(startTime, date);
  const ed = endTime instanceof Date ? endTime : dateFromHourMinutes(endTime, date);
  const totalDiffMinutes = Math.round((ed - sd) / 60000);
  const hours = totalDiffMinutes / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = String(Math.round(minutes)).padStart(2, '0');

  return `${rhours}:${rminutes}`;
}

/**
 * Return hours and minute in backend string format hh:mm from Date object.
 *
 * @param {Date} date
 * @returns {string}
 */
export function getHourMinuteValue(date) {
  if (!date) {
    return '';
  }
  const hours = String(date.getHours());
  const hoursWithLeadingZero = hours.padStart(2, '0');
  const minutes = String(date.getMinutes());
  const minutesWithLeadingZero = minutes.padStart(2, '0');

  return `${hoursWithLeadingZero}:${minutesWithLeadingZero}`;
}

/**
 * Create date object from string.
 *
 * @param {string} hourMinutes - in format hh:mm
 * @param {null|string|Date} currentDate
 * @returns {Date}
 */
export function dateFromHourMinutes(hourMinutes, currentDate = null) {
  const isValid = hourMinuteRegex.test(hourMinutes);
  if (!isValid) {
    throw new Error(hourMinuteRegexMessage);
  }

  const d = currentDate ? new Date(currentDate) : new Date();
  const hours = Number(hourMinutes.split(':')[0]);
  const minutes = Number(hourMinutes.split(':')[1]);

  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), hours, minutes);
}

/**
 * Find date properties in object and convert them to hh:mm strings.
 * Returns the object with converted properties.
 *
 * @param {object} otherValues
 * @returns {object}
 */
export function dateDtoValues(otherValues) {
  return Object.keys(otherValues).reduce((obj, key) => ({
    ...obj,
    [key]: otherValues[key] instanceof Date ? getHourMinuteValue(otherValues[key]) : otherValues[key],
  }), {});
}

export function formatDateYyyyMmDd(date) {
  return date.toISOString().split('T')[0];
}
