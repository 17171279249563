import { useMutation, useQuery, useQueryClient } from 'react-query';
import { oktaAuth } from '../auth/oktaAuth';
import { API_ROOT } from '../Constants';
import { plants } from '../components/utils';
import { formatDateYyyyMmDd } from './date';

export const defaultPostHeaders = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export function useGetShiftData(filterParams) {
  const { plantId, ...otherParams } = filterParams;
  let queryString = '';
  if (otherParams) {
    queryString = '?' + new URLSearchParams(otherParams).toString();
  }
  const url = `/shifts/${plantId}${queryString}`;

  return useQuery({
    queryKey: ['shifts', plantId, queryString],
    queryFn: () => getDataFromApi(url),
  });
}

export function useSaveShiftData() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (dto) => {
      const { plantId, isNew, id, ...requestBody } = dto;
      const url = isNew? `/shifts/${plantId}` : `/shifts/${plantId}/${id}`;
      const method = isNew ? 'POST' : 'PATCH';
      const res = await pushDataToApi(
        url,
        defaultPostHeaders,
        JSON.stringify(requestBody),
        method,
      );

      return res.json();
    },
    onSuccess: () => {
      // Invalidating the data after a mutation too quickly will result in getting
      // the old data back from Elasticsearch because it needs a second to reindex.
      // We wait a second to invalidate the queryKey of method useGetShiftData to
      // automatically get the newly added data.
      setTimeout(() => {
        client.invalidateQueries({ queryKey: ['shifts'] });
      }, 1000);
    }
  });
}

export function useExportShiftData() {
  return useMutation({
    mutationFn: async (filterParams) => {
      const { plantId, startDate, endDate } = filterParams;
      const fromDate = formatDateYyyyMmDd(startDate);
      const toDate = formatDateYyyyMmDd(endDate);
      const queryString = new URLSearchParams({
        fromDate,
        toDate,
      }).toString();
      const url = `/shifts/${plantId}/export?${queryString}`;
      const response = await getDataFromApi(url, { headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }});
      const blob = await response.clone().blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `shifts_${plantId}_${fromDate}_${toDate}.xlsx`;
      link.click();
    },
  });
}

export function useDeleteShiftData() {
  return useMutation({
    mutationFn: async (dto) => {
      const { plantId, id } = dto;
      const url = `/shifts/${plantId}/${id}`;
      return deleteDataFromApi(url);
    }
  });
}

export function useGetBreakData(filterParams) {
  const { plantId, ...otherParams } = filterParams;
  let queryString = '';
  if (otherParams) {
    queryString = '?' + new URLSearchParams(otherParams).toString();
  }
  const url = `/breaks/${plantId}${queryString}`;

  return useQuery({
    queryKey: url,
    queryFn: () => getDataFromApi(url),
  });
}


export function useSaveBreakData() {
  return useMutation({
    mutationFn: async (dto) => {
      const { plantId, isNew, id, ...requestBody } = dto;
      const url = isNew? `/breaks/${plantId}` : `/breaks/${plantId}/${id}`;
      const method = isNew ? 'POST' : 'PATCH';
      const res = await pushDataToApi(
        url,
        defaultPostHeaders,
        JSON.stringify(requestBody),
        method,
      );

      return res.json();
    },
  });
}

export function useDeleteBreakData() {
  return useMutation({
    mutationFn: async (dto) => {
      const { plantId, id } = dto;
      const url = `/breaks/${plantId}/${id}`;
      return deleteDataFromApi(url);
    }
  });
}

export function useGetReportsData() {
  return useQuery({
    queryKey: 'reports',
    queryFn: async () => {
      const allReports = [];
      for (const plant of plants) {
        const reports = await getDataFromApi(`/reports/${plant.id}`);
        allReports.push({
          plantId: plant.id,
          reports,
        });
      }
      return allReports;
    },
  });
}

export function getApiDate (value) {
  if (value instanceof Date) {
    return value;
  }
  if (typeof value !== 'string') {
    return null;
  }
  return new Date(value);
}

/**
 * @param {Object<string, string>} [options] `Fetch` options Object
 *
 * @returns {Object<string, string>} Resolves to options
 */
function addOktaBearerToFetchOptions(options) {
  const allOptions = { ...options };
  const accessToken = oktaAuth.getAccessToken();
  const headerValue = `Bearer ${accessToken}`;
  const headerName = 'Authorization';

  // Other headers might already exist
  if (allOptions.headers) {
    // headers can be `Headers`
    if (allOptions.headers.set) {
      allOptions.headers.set(headerName, headerValue);
    } else {
      // or simple JSON
      allOptions.headers[headerName] = headerValue;
    }
  } else {
    // Just add simple JSON instead
    allOptions.headers = {
      [headerName]: headerValue,
    };
  }
  // Original object is modified, but returning to be polite
  return allOptions;
}

/**
 * Post data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {object} options // Options to send with the request.
 * @param {string} body // Body to send with the request.
 * @param {string} method // Request method POST or PATCH.
 */
export async function pushDataToApi(endpoint, options = {}, body = '', method = 'POST') {
  const authOptions = addOktaBearerToFetchOptions(options);

  return fetch(`${API_ROOT}${endpoint}`, {
    method: method,
    ...authOptions,
    body,
  })
    .then(async (res) => {
      if (!res.ok) {
        const isJson = res.headers.get('content-type').includes('application/json');
        const body = isJson
          ? await res.clone().json()
          : { message: getErrorResponseMessage(res) };
        throw Error(body.message);
      }
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/**
 * DELETE data from API.
 *
 * @param {string} endpoint
 * @returns {Promise<Response>}
 */
async function deleteDataFromApi(endpoint) {
  return pushDataToApi(endpoint, {}, '', 'DELETE');
}

/**
 * GET data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {object} options // Options to send with the request.
 */
export async function getDataFromApi(endpoint, options = {}) {
  const authOptions = addOktaBearerToFetchOptions(options);

  return fetch(`${API_ROOT}${endpoint}`, {
    method: 'GET',
    ...authOptions,
  })
    .then(async (res) => {
      const isJson = res.headers.get('content-type').includes('application/json');
      if (isJson && res.ok) {
        return await res.json();
      }
      if (!res.ok) {
        const body = isJson
          ? await res.clone().json()
          : { message: getErrorResponseMessage(res) };
        throw Error(body.message);
      }
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

/**
 * Get the error message from the API response.
 * @param {object} res
 * @returns {string}
 */
function getErrorResponseMessage(res) {
  if (res.status === 403) {
    return 'You are not authorized to perform this action.';
  }

  if (res.status === 401) {
    return 'You are not authenticated.';
  }

  return 'An error occurred.';
}
