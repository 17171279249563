import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  TextField,
  DialogTitle,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { useCallback, useRef, useState } from 'react';
import usePlantsRoute from '../hooks/usePlantsRoute';
import { useExportShiftData } from '../hooks/api';

export default function ExportDialog ({ open, onClose }) {
  const { id: plantId } = usePlantsRoute();
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = useCallback(() => setSnackbar(null), [setSnackbar]);
  const today = new Date();
  // Default start date is the last month
  const lastMonth = new Date(today);
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const mutation = useExportShiftData();
  const initialValues = {
    startDate: lastMonth,
    endDate: today,
  };
  const formEl = useRef();
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      // Test date range
      if (values.startDate > values.endDate) {
        errors.startDate = 'Start date cannot exceed end date';
      }

      return errors;
    },
    onSubmit: async (dto) => {
      try {
        const { startDate, endDate } = dto;
        await mutation.mutateAsync({ plantId, startDate, endDate });
        setSnackbar({ children: 'Shifts exported to downloads', severity: 'success' });
      } catch (e) {
        setSnackbar({ children: 'Could not export shifts, try again later please', severity: 'error' });
      } finally {
        onClose();
      }
    },
  });
  // The submit button is outside the form element because it needs to be in the DialogAction element.
  const onButtonSubmitClick = () => {
    formEl && formEl.current.dispatchEvent(new Event('submit', { cancelable: false, bubbles: true }));
  };

  return (
    <>
      <Dialog PaperProps={{ sx: { width: "50%"} }} onClose={onClose} open={open}>
        <DialogTitle>
          Export Shifts
        </DialogTitle>
        <DialogContent dividers>
          <form ref={formEl} onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    label="Start date"
                    name="startDate"
                    type="date"
                    mask="__-__-____"
                    inputFormat="DD-MM-YYYY"
                    value={formik.values.startDate}
                    onChange={(value) => formik.setFieldValue('startDate', value.$d)}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (<TextField
                        {...params}
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.errors.startDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    label="End date"
                    name="endDate"
                    type="date"
                    mask="__-__-____"
                    inputFormat="DD-MM-YYYY"
                    value={formik.values.endDate}
                    onChange={(value) => formik.setFieldValue('endDate', value.$d)}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (<TextField
                        {...params}
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.errors.endDate}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" fullWidth type="button" onClick={onButtonSubmitClick}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}
