import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import { getApiDate } from '../hooks/api';
import { LinearProgress, TextField } from '@mui/material';
import { lineNumbers, useGridState } from './utils';
import ShiftsToolbar from './ShiftsToolbar';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  dateFromHourMinutes,
  getHourMinuteValue,
  hourMinuteRegex,
  hourMinuteRegexMessage,
  shiftLengthCalc
} from '../hooks/date';

export default function ShiftsGrid ({ rows, setRows, rowCount, setPage, page, pageSize, setDateRange, isLoading, onRowSave, onRowDelete }) {
  const { getActions, rowModesModel, setRowModesModel,
    handleRowEditStart, handleRowEditStop, processRowUpdate } = useGridState(onRowSave, onRowDelete, rows, setRows);
  const timeField = {
    preProcessEditCellProps: validateEditTime,
    renderEditCell: renderEditTime,
  };

  const columns = [
    { field: 'lineNumber', headerName: 'Line', editable: true, type: 'singleSelect', valueOptions: lineNumbers, width: 100 },
    { field: 'date', headerName: 'Day', editable: true, type: 'date', width: 100, valueGetter: ({ value }) => getApiDate(value) },
    { field: 'startTime', headerName: 'Start Time', editable: true, width: 120, ...timeField },
    { field: 'endTime', headerName: 'End Time', editable: true, width: 120, ...timeField },
    { field: 'morningBreak', headerName: 'Morning Break', editable: true, width: 120, ...timeField },
    { field: 'lunchBreak', headerName: 'Lunch Break', editable: true, width: 120, ...timeField },
    { field: 'afternoonBreak', headerName: 'Afternoon Break', editable: true, width: 120, ...timeField },
    { field: 'plannedQuantity', headerName: 'Quantity', editable: true, width: 100, type: 'number' },
    { field: 'team', headerName: 'Team', editable: true, width: 100, type: 'number' },
    { field: 'shiftLength', headerName: 'Shift length', editable: false, width: 100, valueGetter: shiftLengthCalc },
    { field: 'actions', type: 'actions', headerName: 'Actions', width: 100, cellClassName: 'actions', getActions: getActions },
  ];

  return (
    <div className="grid-wrapper">
      <div className="grid-container">
        <DataGrid
          paginationMode='server'
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          loading={isLoading}
          rowCount={rowCount}
          onPageChange={(newPage) => setPage(newPage)}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: ShiftsToolbar,
            LoadingOverlay: LinearProgress,
          }}
          disableColumnFilter
          componentsProps={{
            toolbar: { setDateRange },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>
    </div>
  );
}

function validateEditTime(params) {
  if (!params.props.value) {
    return null;
  }

  const hourMinuteValue = typeof params.props.value === 'string'
    ? params.props.value
    : getHourMinuteValue(params.props.value);
  const isValid = hourMinuteRegex.test(hourMinuteValue);
  const errorMsg = isValid
    ? null
    : hourMinuteRegexMessage;
  return { ...params.props, error: errorMsg };
}

function TimePickerEditInputCell(props) {
  const { id, value, field, error } = props;
  const dateObj = value instanceof Date ? value : dateFromHourMinutes(value, props.row.date.toISOString());
  const apiRef = useGridApiContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        required
        id={id}
        name={field}
        ampm={false}
        views={["hours", "minutes"]}
        value={dateObj}
        onChange={(tDate) => {
          const newValue = dateFromHourMinutes(getHourMinuteValue(tDate.$d), props.row.date.toISOString());
          // console.log(getHourMinuteValue(tDate.$d), props.row.date.toISOString());
          // console.log(newValue);
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        }}
        renderInput={(params) => (<TextField
            {...params}
            error={Boolean(error)}
            helperText={error}
          />
        )}
      />
    </LocalizationProvider>
  );
}

function renderEditTime(props) {
  return <TimePickerEditInputCell {...props} />;
}
