import { GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterDateRange from './FilterDateRange';
import { Grid } from '@mui/material';
import { gridBox, gridButton, gridFilterTitle } from '../assets/styling/grid';
import { Box } from '@mui/system';
import { useState } from 'react';
import ShiftsDialog from './ShiftsDialog';
import ExportDialog from './ExportDialog';

export default function ShiftsToolbar ({ setDateRange }) {
  const [openShifts, setOpenShifts] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const onClickAdd = () => setOpenShifts(true);
  const onClickExport = () => setOpenExport(true);

  return (
    <GridToolbarContainer>
      <Box sx={gridBox}>
        <Grid sx={gridFilterTitle}>Shifts</Grid>
        <Button sx={gridButton} variant="contained" color="primary" startIcon={<AddIcon/>} onClick={onClickAdd}>Add</Button>
      </Box>
      <Box sx={gridBox}>
        <Grid sx={gridFilterTitle}>Shifts</Grid>
        <Button sx={gridButton} variant="contained" color="primary" startIcon={<FileDownloadIcon/>} onClick={onClickExport}>Export</Button>
      </Box>
      <Box sx={gridBox}>
        <Grid sx={gridFilterTitle}>Filter Day</Grid>
        <FilterDateRange setFilterValue={setDateRange}/>
      </Box>
      <ShiftsDialog
        open={openShifts}
        onClose={() => setOpenShifts(false)}
      />
      <ExportDialog
        open={openExport}
        onClose={() => setOpenExport(false)}
      />
    </GridToolbarContainer>
  );
}
