import { useCallback, useState } from 'react';
import { GridActionsCellItem, GridRowModes } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import fabriekDieren from '../assets/images/fabriekDieren.jpeg';
import fabriekEmstek from '../assets/images/fabriekEmstek.jpeg';
import fabriekLitouwen from '../assets/images/fabriekLitouwen.webp';

export function useGridState (onRowSave, onRowDelete, rows, setRows) {
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStart = useCallback((params, event) => {
    event.defaultMuiPrevented = false;
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    event.defaultMuiPrevented = false;
  }, []);

  const handleEditClick = useCallback((id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  }, [rowModesModel]);

  const handleSaveClick = useCallback((id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  }, [rowModesModel]);

  const handleDeleteClick = useCallback((id) => async () => {
    const ok = await onRowDelete(id);
    if (ok) {
      setRows(rows.filter((row) => row.id !== id));
    }
  }, [onRowDelete, rows, setRows]);

  const handleCancelClick = useCallback((id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  }, [rowModesModel, rows, setRows]);

  const processRowUpdate = useCallback(async (newRow, oldRow) => {
    // The id will be returned from the backend storage.
    const id = await onRowSave(newRow);
    // Return the old row when things fail.
    if (!id) {
      return oldRow;
    }
    const updatedRow = {
      ...newRow,
      id: id,
      isNew: false
    };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  }, [onRowSave, rows, setRows]);

  const getActions = useCallback(({ id }) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
      return [
        <GridActionsCellItem
          icon={<SaveIcon/>}
          label="Save"
          onClick={handleSaveClick(id)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon/>}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />,
      ];
    }

    return [
      <GridActionsCellItem
        icon={<EditIcon/>}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
      />,
      <GridActionsCellItem
        icon={<DeleteIcon/>}
        label="Delete"
        onClick={handleDeleteClick(id)}
        color="inherit"
      />,
    ];
  }, [handleCancelClick, handleDeleteClick, handleEditClick, handleSaveClick, rowModesModel]);

  return {
    getActions,
    rowModesModel,
    setRowModesModel,
    handleRowEditStart,
    handleRowEditStop,
    processRowUpdate
  };
}

export const dierenGroup = 'Endoflines Dieren';
export const kalkhoffGroup = 'Endoflines Kalkhoff';
export const kedainiaiGroup = 'Endoflines Kedainiai';
export const keyUserGroup = 'Endoflines Keyuser';
export const readOnlyGroup = 'Endoflines Readonly';
export const originalLines = ['EM01', 'EM02', 'EM03', 'EM04', 'EM05', 'EM06'];
export const cellAssemblyLines = ['CA01'];
export const preAssemblyLines = ['cwsrvm04', 'cwsrvm05', 'cwsrvm06'];
export const lineNumbers = ['', ...originalLines, ...cellAssemblyLines, ...preAssemblyLines];
export const plants = [
  {
    id: 'dieren',
    label: 'Dieren',
    description: 'The Gazelle Innovation and Production Center in the village Dieren.',
    image: fabriekDieren,
    group: dierenGroup,
  },
  {
    id: 'kalkhoff',
    label: 'Kalkhoff Emstek',
    description: 'The new factory in Ecopark Emstek',
    image: fabriekEmstek,
    group: kalkhoffGroup,
  },
  {
    id: 'kedainiai',
    label: 'Kedainiai Lithuania',
    description: 'The factory in the village Kedainiai Lithuania',
    image: fabriekLitouwen,
    group: kedainiaiGroup,
  }
];

/**
 * Test if the user has access to the plant.
 *
 * @param {object} user - The user object.
 * @param {string} plantId - The plant id.
 * @returns {boolean} - True if the user has access to the plant.
 */
export function hasPlantAccess(user, plantId) {
  const plant = plants.find((p) => p.id === plantId);
  if (!plant) {
    return false;
  }
  return user.groups.includes(plant.group);
}

/**
 * Test if the user has access to key user functionality.
 *
 * @param {object} user
 * @returns {boolean}
 */
export function hasKeyUserAccess(user) {
  return user.groups.includes(keyUserGroup);
}
